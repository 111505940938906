import React from "react";
import { useHorizontalScroll } from "../../lib/hooks/useHorizontalScroll";
import RoadmapItem from "./RoadmapItem";

const Roadmap = () => {
  const roadmap_items = [
    {
      roadmapTitle: "",
      title: "Platform Development",
      info: [
        "1. Build Core AI trading algorithms, Solana sniping bots, and predictive engine. 2. Token creation and ICO/IEO for early investors. 3. Launch staking and deposit systems for initial traders."
        ],
    },
    {
      roadmapTitle: "",
      title: "AI Refinement & Staking Program",
      info: [
        "1. Roll out staking opportunities with fundleasing options for traders. 2. Improve the AI trading algorithm based on live market data.",
      ],
    },
    {
      roadmapTitle: "",
      title: "Release & Governance Integration",
      info: [
        "1. Integrate Solana sniping bots, allowing users to activate bots for Solana DEX trading. 2. Begin decentralizing platform governance with a DAO system.",
      ],
    },

    

    {
      roadmapTitle: "",
      title: "Global Expansion & Advanced Features",
      info: [
        "1. Expansion of predictive capabilities to cover additional asset classes like stocks and forex. 2. Launch premium features for token holders, including early access to new trading algorithms.",
      ],
    },
    {
      roadmapTitle: "",
      title: "Index Fund",
      info: [
        "1. Launch of a diversified index fund for stable returns. 2. Focus on high-growth sectors within the crypto market. 3. Regular rebalancing to optimize performance. 4. Educational resources for investors on fund management.",
      ],
    },
    
  ];

  const scrollRef = useHorizontalScroll();

  return (
    <section id="roadmap" className="roadmap-area pt-130 pb-130">
      <div className="container custom-container-two">
        <div className="row justify-content-center">
          <div className="col-xl-5 col-lg-8">
            <div className="section-title text-center mb-60">
              <span className="sub-title">Our Roadmap</span>
              <h2 className="title">
                Coin Earn Exchange Strategy and <br /> Project <span>Plan</span>
              </h2>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-lg-12">
            <div
              className="bt-roadmap_x bt-roadmap-scrollbar"
              ref={scrollRef}
              style={{ overflow: "auto" }}
            >
              <div className="bt-roadmap-wrap">
                {roadmap_items.map((x, index) => (
                  <RoadmapItem key={index} item={x} />
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Roadmap;
