import React from "react";
import { useFormik } from "formik";
import { signUpSchema } from "./schemas";
import axios from "axios";
import TextField from "@mui/material/TextField";
import "../../src/assets/css/my_style.css"




const initialValues = {
  name: "",
  email: "",
  password: "",
  cpass: ""
};

const Sign = () => {
  const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
    useFormik({
      initialValues,
      validationSchema: signUpSchema,
      onSubmit: (values, action) => {
        axios.post("http://localhost:8080/users/post", {
          name: values.name,
          email: values.email,
          password: values.password,
          cpass: values.cpass
        });
        console.log(values);
        action.resetForm();

      },
    });
  return (
    <section id="contact" className="contact-area pt-70 pb-110">
      <div className="contact-form-wrap my-log my_contact_padding">
        <form action="#">
          <div className="row">
            <div className="col-md-12">
              <div className="sign_up"><h2>Sign Up</h2>
              </div>
            </div>
            <div className="m_text">
              <TextField
                name="name" value={values.name}
                onChange={handleChange}
                onBlur={handleBlur}
                id="outlined-basic"
                variant="outlined"
                fullWidth
                size="normal"
                placeholder="Enter Your Name"
                sx={{
                  input: {
                    color: "white",
                    background: "#0b1d33",
                    idth: "100%",
                    borderColor: 'grey.500',
                    borderRadius: 2,
                    height: "35px",

                  }
                }}
              />
              {errors.name && touched.name ? (
                <p className="form-error">{errors.name}</p>
              ) : null}
            </div>
            <div className="m_text">
              <TextField
                name="email" value={values.email}
                onChange={handleChange}
                onBlur={handleBlur}
                id="outlined-basic"
                variant="outlined"
                fullWidth
                size="normal"
                placeholder="Enter Your email"
                sx={{
                  input: {
                    color: "white",
                    background: "#0b1d33",
                    idth: "100%",
                    borderColor: 'grey.500',
                    borderRadius: 2,
                    height: "35px",
                  }
                }}
              />
              {errors.email && touched.email ? (
                <p className="form-error">{errors.email}</p>
              ) : null}
            </div>
            <div className="m_text">
              <TextField
                name="password" value={values.password}
                onChange={handleChange}
                onBlur={handleBlur}
                id="outlined-basic"
                variant="outlined"
                fullWidth
                size="normal"
                placeholder="Enter Your Password"
                sx={{
                  input: {
                    color: "white",
                    background: "#0b1d33",
                    idth: "100%",
                    borderColor: 'grey.500',
                    borderRadius: 2,
                    height: "35px",
                  }
                }}
              />
              {errors.password && touched.password ? (
                <p className="form-error">{errors.password}</p>
              ) : null}
            </div>
            <div className="m_text">
              <TextField
                name="cpass" value={values.cpass}
                onChange={handleChange}
                onBlur={handleBlur}
                id="outlined-basic"
                variant="outlined"
                fullWidth
                size="normal"
                placeholder="Enter Your Confirm Password"
                sx={{
                  input: {
                    color: "white",
                    background: "#0b1d33",
                    idth: "100%",
                    borderColor: 'grey.500',
                    borderRadius: 2,
                    height: "35px",
                  }
                }}
              />
              {errors.cpass && touched.cpass ? (
                <p className="form-error">{errors.cpass}</p>
              ) : null}
            </div>
            <div className="submit-btn text-center">
              <button type="submit" className="btn" onClick={handleSubmit}>
                Sign Up
              </button>
            </div>
            <div className="sign_up_">

            </div>
          </div>
        </form >
      </div >

    </section >


  );
};

export default Sign;
