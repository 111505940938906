import React from "react";
import { Link } from "react-router-dom";


import LayoutOne from "../layouts/LayoutOne";
import "../../src/assets/css/my_style.css"

const Log = () => {
  return (
   
          <section id="contact" className="contact-area pt-70 pb-110">
            <div className="contact-form-wrap my-log my_contact_padding">
              <form action="#">
                <div className="row">
                  <div className="col-md-12">
                    <div className="sign_up"><h2>Log in</h2></div>
                    
                  </div>

                  <div className="col-md-12">
                    <div className="form-grp">
                      <input type="password" placeholder="Enter you Email" required />
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="form-grp">
                      <input type="password" placeholder="Password" required />
                    </div>
                  </div>

                </div>

                <div className="submit-btn text-center">
                  <button type="submit" className="btn">
                    Sign Up
                  </button>
                </div>
                <div className="sign_up_">
                
                </div>
              </form>
            </div>
          </section>
  
  );
};

export default Log;
