import { Routes, Route } from "react-router-dom";
import Home from "./pages/Home/Home";
import { useEffect } from "react";
import WOW from "wow.js";
import HomeTwo from "./pages/Home/HomeTwo";
import Blog from "./pages/Blog/Blog";
import Login from "./components/Login";
import SignLogin from "./components/SignLogin";
import Signup from "./components/Signup";
import BlogDetailsPage from "./pages/BlogDetails/BlogDetailsPage";
import Adminindex from "./Admin/Adminindex";
import Productadd from "./Admin/Productadd";
import Products from "./Admin/Products";
import Orders from "./Admin/Orders";
import Map from "./Admin/Map";
import LogIn from "./Admin/LogIn";
import Admin from "./Admin/Admin";
import {User} from "./Admin/User";
import {Ceecontact} from "./Admin/Ceecontact";
import { useLocation } from "react-router-dom";


function App() {
  useEffect(() => {
    const wow = new WOW({
      boxClass: "wow",
      animateClass: "animated",
      offset: 0,
      mobile: false,
      live: true,
    });
    wow.init();
  }, []);

  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="home-two" element={<HomeTwo />} />
        <Route path="blog" element={<Blog />} />
        <Route path="login" element={< Login/>} />
        <Route path="signup" element={< Signup/>} />
        <Route path="signlogin" element={< SignLogin/>} />
        <Route path="blog-details" element={<BlogDetailsPage />} />
        <Route path="/admin" element={<Adminindex/>}/>
          <Route path="/adminpanel/Productadd" element={<Productadd/>}/>
          <Route path="/adminpanel/Products" element={<Products/>}/>
          <Route path="/adminpanel/Orders" element={<Orders/>}/>
          <Route path="/adminpanel/Map" element={<Map/>}/>
          <Route path="/adminpanel/LogIn" element={<LogIn/>}/>
          <Route path="/adminpanel/Admin" element={<Admin/>}/>
          <Route path="/adminpanel/User" element={<User/>}/>
          <Route path="/adminpanel/Ceecontact" element={<Ceecontact/>}/>
      </Routes>
    </>
  );
}

export default App;
