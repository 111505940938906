import React from "react";
import { useFormik } from "formik";
import { contactSchema } from "../schemas";
import axios from "axios";
import TextField from "@mui/material/TextField";
import "../../../src/assets/css/my_style.css"
import Textarea from '@mui/joy/Textarea';



const initialValues = {
  name: "",
  email: "",
  message: "",

};

const ContactOneForm = () => {
  const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
    useFormik({
      initialValues,
      validationSchema: contactSchema,
      onSubmit: (values, action) => {
        axios.post("http://localhost:8080/ceecontact/post", {
          name: values.name,
          email: values.email,
          message: values.message,
        });
        console.log(values);
        action.resetForm();

      },
    });
  return (
    <div className="contact-form-wrap">
      <form action="#">
        <div className="row">
          <div className="col-md-6">

            <div className="m_text">
              <TextField
                name="name" value={values.name}
                onChange={handleChange}
                onBlur={handleBlur}
                id="outlined-basic"
                variant="outlined"
                fullWidth
                size="normal"
                placeholder="Enter Your Name"
                sx={{
                  input: {
                    color: "white",
                    background: "#0b1d33",
                    idth: "100%",
                    borderColor: 'grey.500',
                    borderRadius: 2,
                    height: "20px",

                  }
                }}
              />
              {errors.name && touched.name ? (
                <p className="form-error">{errors.name}</p>
              ) : null}

            </div>
          </div>
          <div className="col-md-6">
            <div className="m_text">
              <TextField
                name="email" value={values.email}
                onChange={handleChange}
                onBlur={handleBlur}
                id="outlined-basic"
                variant="outlined"
                fullWidth
                size="normal"
                placeholder="Enter Your Email"
                sx={{
                  input: {
                    color: "white",
                    background: "#0b1d33",
                    idth: "100%",
                    borderColor: 'grey.500',
                    borderRadius: 2,
                    height: "20px",

                  }
                }}
              />
              {errors.email && touched.email ? (
                <p className="form-error">{errors.email}</p>
              ) : null}

            </div>
          </div>

        </div>
        <div className="col-md-12">
        <div className="m_text_message">
          <TextField
            name="message" value={values.message}
            onChange={handleChange}
            onBlur={handleBlur}
            id="outlined-basic"
            variant="outlined"
            // sx={{
            //   input: {
            //     color: "white",
            //     background: "#0b1d33",
            //     width: "100%",
            //     borderColor: 'grey.500',
            //     borderRadius: 2,
            //     height:"100px"

            //   }

            // }}
            InputProps={{
              style: {
                backgroundColor: '#0b1d33',
                color: "white",
                borderColor: 'grey.500',
                borderRadius: 2,
              },
            }}
            fullWidth={true}
            multiline
            rows={5}
            rowsMax={10}
            placeholder="Type Your Message..."

          />
          {errors.message && touched.message ? (
            <p className="form-error">{errors.message}</p>
          ) : null}
          </div>
        </div>
        <div className="submit-btn text-center">
          <button type="submit" className="btn" onClick={handleSubmit}>
            Send Massage
          </button>
        </div>
      </form>
    </div>
  );
};

export default ContactOneForm;
