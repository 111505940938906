import React from "react";
import "./css/style.css";
import "./css/mystyle.css";
import Productadd from "./Productadd";
import { Link } from "react-router-dom";
import img from "./images/logo.svg";


const Adminindex = () => {
  const sidebar = [
    {
      id: "03",
      menu: "Ceecontact",
      linkto: "/Adminpanel/Ceecontact",
    },
    {
      id: "01",
      menu: "Admin",
      linkto: "/Adminpanel/Admin",
    },
    {
      id: "02",
      menu: "Users",
      linkto: "/Adminpanel/User",
    },


    // {
    //   id: "04",
    //   menu: "Products",
    //   linkto: "/Adminpanel/Products",
    // },
    // {
    //   id: "004",
    //   menu: "Add Products",
    //   linkto: "/Adminpanel/Productadd",
    // },
    // {
    //   id: "05",
    //   menu: "Map",
    //   linkto: "/Adminpanel/Map",
    // },
    // {
    //   id: "06",
    //   menu: "Login",
    //   linkto: "/Adminpanel/LogIn",
    // },
  ];
  return (
    <>
      <section id="container">
        <header className="header fixed-top clearfix">
          <div className="brand">
            <Link to="/" className="logo">
              <img src={img}  />
            </Link>
          </div>
          <div className="nav notify-row" id="top_menu"></div>
          <div className="top-nav clearfix">
            <ul className="nav pull-right top-menu">
              <li className="dropdown">
                <a data-toggle="dropdown" className="dropdown-toggle" href="#">
                  <img alt="" src="images/2.png" />
                  <span className="username">John Doe</span>
                  <b className="caret" />
                </a>
                <ul className="dropdown-menu extended logout">
                  <li>
                    <a href="#">
                      <i className=" fa fa-suitcase" />
                      Profile
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <i className="fa fa-cog" /> Settings
                    </a>
                  </li>
                  <li>
                    <a href="login.html">
                      <i className="fa fa-key" /> Log Out
                    </a>
                  </li>
                </ul>
              </li>
            </ul>
          </div>
        </header>
        <aside>
          <div id="sidebar" className="nav-collapse">
            <div className="leftside-navigation">
              <ul className="sidebar-menu" id="nav-accordion">
                <li>
                  <Link to="/admin" className="active">
                    <i className="fa fa-dashboard" />
                    <span>Dashboard</span>
                    </Link>
                  
                </li>
                {sidebar.map((ddd) => {
                  return (
                    <>
                      <li className="sub-menu">
                        <Link to={ddd.linkto}>
                          <i className=" fa fa-bar-chart-o" />
                          <span>{ddd.menu}</span>
                        </Link>
                      </li>
                    </>
                  );
                })}
              </ul>{" "}
            </div>
          </div>
        </aside>
        {/* <section id="main-content" className="mfooter">
          <div className="footer">
            <div className="wthree-copyright">
              <p>
                © 2017 Visitors. All rights reserved | Design by{" "}
                <a href="#">Shree Vachanamrut Art &amp; Creation</a>
              </p>
            </div>
          </div>
        </section> */}
      </section>
    </>
  );
};

export default Adminindex;
