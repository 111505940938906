import React, { useState, useEffect, StrictMode, useRef } from 'react'
import Adminindex from "./Adminindex";
import "./css/mystyle.css";
import { AgGridReact } from 'ag-grid-react';
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import axios from 'axios';




export const User = () => {
  const gridRef = useRef()
  const [rowData, setRowData] = useState([]);
  const [gridApi, setGridApi] = useState(null);
  const fetchData = async () => {
    try {
      const response = await axios.get("http://localhost:8080/users/getall");
      const transformedData = response.data.map((user) => ({
        id: user._id,
        name: user.name,
        email: user.email, // Adding random age for demo
      }));
      setRowData(transformedData);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  useEffect(() => {
    fetchData();
  }, []);
  const reversedData = [...rowData].reverse();
  const [colDefs, setColDefs] = useState([
    {
      headerCheckboxSelection: true,
      checkboxSelection: true,
      headerName: "Select",
      width: 100
    },
    { headerName: "id", field: "_id" },
    { headerName: "Name", field: "name" },
    { headerName: "Email", field: "email" },
    { headerName: "Password", field: "password" }

  ]);
  const onGridReady = (params) => {
    setGridApi(params.api);
  };
  const deleteSelectedRows = () => {
    const selectedNodes = gridApi.getSelectedNodes(); // Get selected nodes
    const selectedDataIds = selectedNodes.map((node) => node.data.id); // Get IDs of selected rows

    // Simulate deletion (filter out selected rows)
    setRowData((prevData) => prevData.filter((row) => !selectedDataIds.includes(row.id)));
  };



  return (
    <>
      <Adminindex />

      <div className="mainproduct mg-admin">
        this is user
      </div>
      <div className="mainproduct mg-admin">
        <button onClick={deleteSelectedRows} style={{ marginBottom: "10px" }}>
          Delete Selected
        </button>
      </div>

      <div className={
        "ag-theme-alpine agg-grid"
      }
        style={{ width: '100%', height: '500px' }}
      >
        < AgGridReact rowSelection="multiple" rowData={reversedData} columnDefs={colDefs} ref={gridRef} animateRows={true} onGridReady={onGridReady} />
      </div>



    </>
  )
}


