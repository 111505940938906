import React from "react";
import "./css/mystyle.css";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Button from "@mui/material/Button";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import SaveIcon from "@mui/icons-material/Save";
import Adminindex from "./Adminindex";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";


const Productadd = () => {
  const [name, setname] = React.useState("");
  const [sub, setSub] = React.useState("");
  const [des, setDes] = React.useState("");
  const [vlink, setVlink] = React.useState("");
  const [blink, setBlink] = React.useState("");
  const [price, setPrice] = React.useState("");
  const [category, setCategory] = React.useState("");

  console.log(name);
  const subfinal = async (a) => {
    a.preventDefault();
    await axios.post("http://localhost:8000/products_noimg", {
      name,
      sub,
      des,
      vlink,
      blink,
      price,
      category,
    });
    toast.success('Data inserted Successfully', {
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
      });
    setname("");
    setSub("");
    setDes("");
    setVlink("");
    setBlink("");
    setPrice("");
    setCategory("");
  };
  return (
    <>
      <Adminindex />
      <div className="mainproduct">
        <ToastContainer
          position="top-center"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
        />
        
        <h4 className="addpr">Add Product</h4>
        <Box
          component="form"
          sx={{
            "& .MuiTextField-root": { m: 1, width: "50ch" },
          }}
          noValidate
          autoComplete="off"
        >
          <TextField
            id="outlined-multiline-flexible"
            label={"Title"}
            multiline
            maxRows={2}
            name="name"
            onChange={(e) => setname(e.target.value)}
            value={name}
          />
          <TextField
            id="outlined-multiline-flexible"
            label="Subtitle"
            multiline
            maxRows={2}
            name="sub"
            onChange={(e) => setSub(e.target.value)}
            value={sub}
          />
          <TextField
            id="outlined-multiline-flexible"
            label="Description"
            multiline
            maxRows={2}
            name="des"
            onChange={(e) => setDes(e.target.value)}
            value={des}
          />
          <TextField
            id="outlined-multiline-flexible"
            label="Viewlink"
            multiline
            maxRows={2}
            name="vlink"
            onChange={(e) => setVlink(e.target.value)}
            value={vlink}
          />
          <TextField
            id="outlined-multiline-flexible"
            label="Buylink"
            multiline
            maxRows={2}
            name="blink"
            onChange={(e) => setBlink(e.target.value)}
            value={blink}
          />
          <TextField
            id="outlined-multiline-flexible"
            label="Price"
            multiline
            maxRows={2}
            name="price"
            onChange={(e) => setPrice(e.target.value)}
            value={price}
          />
          {/* <Box>
            <Button
              variant="outlined"
              startIcon={<AttachFileIcon />}
              component="label"
              sx={{ m: 1, width: "50%" }}
            >
              Upload
              <input hidden accept="image/*" multiple type="file" />
            </Button>
          </Box> */}
          <FormControl sx={{ m: 1, minWidth: 200 }}>
            <InputLabel id="demo-simple-select-autowidth-label">Age</InputLabel>
            <Select
              labelId="demo-simple-select-autowidth-label"
              id="demo-simple-select-autowidth"
              autoWidth
              label="Age"
              name="category"
              onChange={(e) => setCategory(e.target.value)}
            >
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
              <MenuItem value={10}>Twenty</MenuItem>
              <MenuItem value={21}>Twenty one</MenuItem>
              <MenuItem value={22}>Twenty one and a half</MenuItem>
            </Select>
          </FormControl>
        </Box>
        <Box>
          <Button
            variant="contained"
            startIcon={<SaveIcon />}
            component="label"
            sx={{ m: 1, width: "80%" }}
            onClick={subfinal}
          >
            Save Data
            <input hidden accept="image/*" multiple type="file" />
          </Button>
        </Box>
      </div>
    </>
  );
};

export default Productadd;
