import React from 'react'
import Adminindex from "./Adminindex";
import "./css/mystyle.css";

const LogIn = () => {
  return (
    <>
    <Adminindex/>
    <div className="mainproduct">
        
      This is login
    </div>
    </>
  )
}

export default LogIn
