import React, { useState, useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import Adminindex from "./Adminindex";
import "./css/mystyle.css";
import axios from "axios";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css";

import "bootstrap/dist/css/bootstrap.min.css";

export default function Products() {
  const [prod, setProd] = useState([]);
  const All_Profile = () => {
    axios.get("http://localhost:8000/products_noimg").then((res) => {
      setProd(res.data);
    });
  };
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const deleteRow = async (id) => {
    confirmAlert({
      title: "Confirm to Delete",
      message: "Are you sure to Delete this.",
      buttons: [
        {
          label: "Yes",
          onClick: () =>
            axios
              .delete(`http://localhost:8000/products_noimg/${id}`)
              .then((res) => {
                toast.success(
                  "Data Has been Deleted Successfully" 
                  ,
                  {
                    position: "top-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                  }
                );
                All_Profile();
              }),
        },
        {
          label: "No",
        },
      ],
    });
  };
  useEffect(() => {
    All_Profile();
  }, []);
  return (
    <>
      <Adminindex />

      <div className="mainproduct mg-admin">
        <ToastContainer
          position="top-center"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
        />
        
        <table className="table">
          <thead>
            <tr>
              <td>Sr</td>
              <td>Name</td>
              <td>Subtitle</td>
              <td>Image</td>
              <td>Description</td>
              <td>ViewLink</td>
              <td>Buylink</td>
              <td>Price</td>
              <td>Action</td>
            </tr>
          </thead>
          <tbody>
            {prod &&
              prod.map((aaa, index) => {
                return (
                  <>
                    <tr>
                      <td>{index + 1}</td>
                      <td>{aaa.name}</td>
                      <td>{aaa.subtitle}</td>
                      <td>{aaa.img}</td>
                      <td>{aaa.desc}</td>
                      <td>{aaa.viewlink}</td>
                      <td>{aaa.buylink}</td>
                      <td>{aaa.price}</td>
                      <td>
                        <EditIcon className="editicon" />
                        <DeleteIcon
                          className="deleteicon"
                          onClick={(e) => deleteRow(aaa.id)}
                        />
                      </td>
                    </tr>
                  </>
                );
              })}
          </tbody>
        </table>
      </div>
    </>
  );
}
