import React, { useState } from "react";
import { Link } from "react-router-dom";


import LayoutOne from "../layouts/LayoutOne";
import "../../src/assets/css/my_style.css"
import Sign from "./Sign"
import Log from "./Log"

const SignLogin = () => {
    const [isLogVisible, setIsLogVisible] = useState(true);
    const [isSignVisible, setIsSignVisible] = useState(false);
    const toggleLogVisibility = () => {
        setIsLogVisible(false);
        setIsSignVisible(true)

    };
    const toggleSignVisibility = () => {
        setIsLogVisible(true);
        setIsSignVisible(false)

    };
    return (
        <LayoutOne>
            <main className="fix">
                <div className="container">

                    <div className="contact-form-wrap my-log my_contact_padding">
                        {isLogVisible && (
                            <Log />
                        )}
                        {isLogVisible && (
                            <p className="mg-log-">Do not have an Account? <a onClick={toggleLogVisibility}className="mg-log"> Signup Now</a></p>
                        )}


                    </div>


                    <div className="contact-form-wrap my-log my_contact_padding">
                        {isSignVisible && (
                            <Sign />
                        )}
                        {isSignVisible && (
                            <p className="mg-log-">Already haves an Account? <a onClick={toggleSignVisibility} className="mg-log">Login Now</a></p>
                        )}

                    </div>

                </div>
            </main>
        </LayoutOne>
    );
};

export default SignLogin;
