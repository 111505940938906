import React from 'react'
import Adminindex from "./Adminindex";
import "./css/mystyle.css";

const Admin = () => {
  return (
    <>
    <Adminindex/>
    <div className="mainproduct mg-admin">
        
      this is admin
    </div>
    </>
  )
}

export default Admin